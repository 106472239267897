import React from 'react';

const QuestionIcon = (props: any) => ( 
<svg width="78px" height="78px" viewBox="0 0 78 78" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="22.-Developer-rebranding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Step-4.9" transform="translate(-608.000000, -215.000000)" fill-rule="nonzero">
            <g id="icons8-code" transform="translate(608.000000, 215.000000)">
                <g id="Group">
                    <circle id="Oval" fill="#78A0CF" cx="2" cy="18" r="2"/>
                    <circle id="Oval" fill="#F1BC19" cx="66" cy="2" r="1"/>
                    <circle id="Oval" fill="#CEE1F4" cx="39" cy="39" r="37"/>
                    <circle id="Oval" fill="#F1BC19" cx="72" cy="4" r="4"/>
                    <circle id="Oval" fill="#78A0CF" cx="76" cy="13" r="2"/>
                    <circle id="Oval" fill="#FBCD59" cx="70" cy="65" r="2"/>
                    <circle id="Oval" fill="#FBCD59" cx="4" cy="52" r="4"/>
                    <circle id="Oval" fill="#78A0CF" cx="14" cy="76" r="2"/>
                    <circle id="Oval" fill="#FFFFFF" cx="7.5" cy="42.5" r="2.5"/>
                    <circle id="Oval" fill="#F1BC19" cx="10" cy="56" r="1"/>
                    <circle id="Oval" fill="#FFFFFF" cx="69" cy="23" r="1"/>
                </g>
                <g id="Group" transform="translate(15.000000, 17.000000)">
                    <path d="M42,43 L6,43 C3.239,43 1,40.761 1,38 L1,6 C1,3.239 3.239,1 6,1 L42,1 C44.761,1 47,3.239 47,6 L47,38 C47,40.761 44.761,43 42,43 Z" id="Path" fill="#FDFCEE"/>
                    <rect id="Rectangle" fill="#EFEDDF" x="1" y="7.5" width="46" height="30"/>
                    <rect id="Rectangle" fill="#836F61" x="0.5" y="7.5" width="21.125" height="30"/>
                    <path d="M42,1.4 C44.536,1.4 46.6,3.464 46.6,6 L46.6,38 C46.6,40.536 44.536,42.6 42,42.6 L6,42.6 C3.464,42.6 1.4,40.536 1.4,38 L1.4,6 C1.4,3.464 3.464,1.4 6,1.4 L42,1.4 L42,1.4 Z M42,0 L6,0 C2.686,0 0,2.686 0,6 L0,38 C0,41.314 2.686,44 6,44 L42,44 C45.314,44 48,41.314 48,38 L48,6 C48,2.686 45.314,0 42,0 L42,0 Z" id="Shape" fill="#472B29"/>
                    <path d="M30.5,8 L0.75,8 C0.474,8 0.25,7.776 0.25,7.5 C0.25,7.224 0.474,7 0.75,7 L30.5,7 C30.776,7 31,7.224 31,7.5 C31,7.776 30.776,8 30.5,8 Z" id="Path" fill="#472B29"/>
                    <path d="M36.5,8 L32.5,8 C32.224,8 32,7.776 32,7.5 C32,7.224 32.224,7 32.5,7 L36.5,7 C36.776,7 37,7.224 37,7.5 C37,7.776 36.776,8 36.5,8 Z" id="Path" fill="#472B29"/>
                    <path d="M47.125,8 L39.5,8 C39.224,8 39,7.776 39,7.5 C39,7.224 39.224,7 39.5,7 L47.125,7 C47.401,7 47.625,7.224 47.625,7.5 C47.625,7.776 47.401,8 47.125,8 Z" id="Path" fill="#472B29"/>
                    <path d="M47.125,38 L42.5,38 C42.224,38 42,37.776 42,37.5 C42,37.224 42.224,37 42.5,37 L47.125,37 C47.401,37 47.625,37.224 47.625,37.5 C47.625,37.776 47.401,38 47.125,38 Z" id="Path" fill="#472B29"/>
                    <path d="M39.5,38 L0.625,38 C0.349,38 0.125,37.776 0.125,37.5 C0.125,37.224 0.349,37 0.625,37 L39.5,37 C39.776,37 40,37.224 40,37.5 C40,37.776 39.776,38 39.5,38 Z" id="Path" fill="#472B29"/>
                    <circle id="Oval" fill="#EE3E54" cx="6.499" cy="4.5" r="1.193"/>
                    <path d="M6.501,3.75 C6.914,3.75 7.25,4.086 7.25,4.5 C7.25,4.914 6.914,5.25 6.501,5.25 C6.087,5.25 5.75,4.914 5.75,4.5 C5.75,4.086 6.087,3.75 6.501,3.75 L6.501,3.75 Z M6.501,3 C5.673,3 5,3.673 5,4.5 C5,5.327 5.673,6 6.501,6 C7.327,6 8,5.327 8,4.5 C8,3.673 7.328,3 6.501,3 L6.501,3 Z" id="Shape" fill="#472B29"/>
                    <circle id="Oval" fill="#F1BC19" cx="10.5" cy="4.5" r="1.193"/>
                    <path d="M10.5,3.75 C10.914,3.75 11.25,4.086 11.25,4.5 C11.25,4.914 10.914,5.25 10.5,5.25 C10.086,5.25 9.75,4.914 9.75,4.5 C9.75,4.086 10.086,3.75 10.5,3.75 L10.5,3.75 Z M10.5,3 C9.673,3 9,3.673 9,4.5 C9,5.327 9.673,6 10.5,6 C11.326,6 12,5.327 12,4.5 C12,3.673 11.327,3 10.5,3 L10.5,3 Z" id="Shape" fill="#472B29"/>
                    <circle id="Oval" fill="#88AE45" cx="14.501" cy="4.5" r="1.193"/>
                    <path d="M14.501,3.75 C14.914,3.75 15.25,4.086 15.25,4.5 C15.25,4.914 14.914,5.25 14.501,5.25 C14.087,5.25 13.75,4.914 13.75,4.5 C13.75,4.086 14.087,3.75 14.501,3.75 L14.501,3.75 Z M14.501,3 C13.673,3 13,3.673 13,4.5 C13,5.327 13.673,6 14.501,6 C15.328,6 16,5.327 16,4.5 C16,3.673 15.328,3 14.501,3 L14.501,3 Z" id="Shape" fill="#472B29"/>
                </g>
                <g id="Group" transform="translate(21.000000, 26.000000)" fill="#472B29">
                    <path d="M15.625,27 C15.418,27 15.25,26.832 15.25,26.625 L15.25,0.375 C15.25,0.168 15.418,0 15.625,0 C15.832,0 16,0.168 16,0.375 L16,26.625 C16,26.832 15.832,27 15.625,27 Z" id="Path"/>
                    <path d="M0.625,27 C0.418,27 0.25,26.832 0.25,26.625 L0.25,0.375 C0.25,0.168 0.418,0 0.625,0 C0.832,0 1,0.168 1,0.375 L1,26.625 C1,26.832 0.832,27 0.625,27 Z" id="Path"/>
                </g>
                <g id="Group" transform="translate(18.000000, 27.000000)" fill="#FDFCEF">
                    <path d="M1.625,1 L0.375,1 C0.168,1 0,0.832 0,0.625 C0,0.418 0.168,0.25 0.375,0.25 L1.625,0.25 C1.832,0.25 2,0.418 2,0.625 C2,0.832 1.832,1 1.625,1 Z" id="Path"/>
                    <path d="M1.625,3 L0.375,3 C0.168,3 0,2.832 0,2.625 C0,2.418 0.168,2.25 0.375,2.25 L1.625,2.25 C1.832,2.25 2,2.418 2,2.625 C2,2.832 1.832,3 1.625,3 Z" id="Path"/>
                    <path d="M1.625,5 L0.375,5 C0.168,5 0,4.832 0,4.625 C0,4.418 0.168,4.25 0.375,4.25 L1.625,4.25 C1.832,4.25 2,4.418 2,4.625 C2,4.832 1.832,5 1.625,5 Z" id="Path"/>
                    <path d="M1.625,7 L0.375,7 C0.168,7 0,6.832 0,6.625 C0,6.418 0.168,6.25 0.375,6.25 L1.625,6.25 C1.832,6.25 2,6.418 2,6.625 C2,6.832 1.832,7 1.625,7 Z" id="Path"/>
                    <path d="M1.625,9 L0.375,9 C0.168,9 0,8.832 0,8.625 C0,8.418 0.168,8.25 0.375,8.25 L1.625,8.25 C1.832,8.25 2,8.418 2,8.625 C2,8.832 1.832,9 1.625,9 Z" id="Path"/>
                    <path d="M1.625,11 L0.375,11 C0.168,11 0,10.832 0,10.625 C0,10.418 0.168,10.25 0.375,10.25 L1.625,10.25 C1.832,10.25 2,10.418 2,10.625 C2,10.832 1.832,11 1.625,11 Z" id="Path"/>
                    <path d="M1.625,13 L0.375,13 C0.168,13 0,12.832 0,12.625 C0,12.418 0.168,12.25 0.375,12.25 L1.625,12.25 C1.832,12.25 2,12.418 2,12.625 C2,12.832 1.832,13 1.625,13 Z" id="Path"/>
                    <path d="M1.625,15 L0.375,15 C0.168,15 0,14.832 0,14.625 C0,14.418 0.168,14.25 0.375,14.25 L1.625,14.25 C1.832,14.25 2,14.418 2,14.625 C2,14.832 1.832,15 1.625,15 Z" id="Path"/>
                    <path d="M1.625,17 L0.375,17 C0.168,17 0,16.832 0,16.625 C0,16.418 0.168,16.25 0.375,16.25 L1.625,16.25 C1.832,16.25 2,16.418 2,16.625 C2,16.832 1.832,17 1.625,17 Z" id="Path"/>
                    <path d="M1.625,19 L0.375,19 C0.168,19 0,18.832 0,18.625 C0,18.418 0.168,18.25 0.375,18.25 L1.625,18.25 C1.832,18.25 2,18.418 2,18.625 C2,18.832 1.832,19 1.625,19 Z" id="Path"/>
                    <path d="M1.625,21 L0.375,21 C0.168,21 0,20.832 0,20.625 C0,20.418 0.168,20.25 0.375,20.25 L1.625,20.25 C1.832,20.25 2,20.418 2,20.625 C2,20.832 1.832,21 1.625,21 Z" id="Path"/>
                    <path d="M1.625,23 L0.375,23 C0.168,23 0,22.832 0,22.625 C0,22.418 0.168,22.25 0.375,22.25 L1.625,22.25 C1.832,22.25 2,22.418 2,22.625 C2,22.832 1.832,23 1.625,23 Z" id="Path"/>
                    <path d="M1.625,25 L0.375,25 C0.168,25 0,24.832 0,24.625 C0,24.418 0.168,24.25 0.375,24.25 L1.625,24.25 C1.832,24.25 2,24.418 2,24.625 C2,24.832 1.832,25 1.625,25 Z" id="Path"/>
                </g>
                <g id="Group" transform="translate(23.000000, 26.000000)" fill="#FDFCEF">
                    <path d="M5.375,8 C5.333,8 5.29,7.993 5.248,7.978 C5.053,7.908 4.952,7.693 5.022,7.498 L7.272,1.248 C7.342,1.053 7.555,0.95 7.752,1.022 C7.947,1.092 8.048,1.307 7.978,1.502 L5.728,7.752 C5.673,7.905 5.529,8 5.375,8 Z" id="Path"/>
                    <path d="M2.625,7 C2.512,7 2.401,6.95 2.327,6.854 L1.077,5.229 C0.973,5.094 0.973,4.907 1.077,4.772 L2.327,3.147 C2.453,2.982 2.689,2.952 2.853,3.078 C3.017,3.204 3.048,3.44 2.922,3.604 L1.848,5 L2.922,6.396 C3.048,6.56 3.017,6.795 2.853,6.922 C2.786,6.975 2.705,7 2.625,7 Z" id="Path"/>
                    <path d="M10.375,7 C10.295,7 10.215,6.975 10.147,6.922 C9.983,6.796 9.952,6.56 10.078,6.396 L11.152,5 L10.078,3.604 C9.952,3.44 9.983,3.205 10.147,3.078 C10.312,2.952 10.546,2.982 10.673,3.147 L11.923,4.772 C12.027,4.907 12.027,5.094 11.923,5.229 L10.673,6.854 C10.598,6.95 10.487,7 10.375,7 Z" id="Path"/>
                </g>
                <g id="Group" transform="translate(40.000000, 29.000000)">
                    <rect id="Rectangle" fill="#C5DEF0" x="0.375" y="1.375" width="11.25" height="7.25"/>
                    <path d="M11.625,9 L0.375,9 C0.168,9 0,8.832 0,8.625 L0,1.375 C0,1.168 0.168,1 0.375,1 L11.625,1 C11.832,1 12,1.168 12,1.375 L12,8.625 C12,8.832 11.832,9 11.625,9 Z M0.75,8.25 L11.25,8.25 L11.25,1.75 L0.75,1.75 L0.75,8.25 Z" id="Shape" fill="#472B29"/>
                    <path d="M17.75,1.5 L14.25,1.5 C14.112,1.5 14,1.388 14,1.25 C14,1.112 14.112,1 14.25,1 L17.75,1 C17.888,1 18,1.112 18,1.25 C18,1.388 17.888,1.5 17.75,1.5 Z" id="Path" fill="#472B29"/>
                    <path d="M17.75,4 L14.25,4 C14.112,4 14,3.888 14,3.75 C14,3.612 14.112,3.5 14.25,3.5 L17.75,3.5 C17.888,3.5 18,3.612 18,3.75 C18,3.888 17.888,4 17.75,4 Z" id="Path" fill="#472B29"/>
                    <path d="M17.75,6.5 L14.25,6.5 C14.112,6.5 14,6.388 14,6.25 C14,6.112 14.112,6 14.25,6 L17.75,6 C17.888,6 18,6.112 18,6.25 C18,6.388 17.888,6.5 17.75,6.5 Z" id="Path" fill="#472B29"/>
                    <path d="M17.75,9 L14.25,9 C14.112,9 14,8.888 14,8.75 C14,8.612 14.112,8.5 14.25,8.5 L17.75,8.5 C17.888,8.5 18,8.612 18,8.75 C18,8.888 17.888,9 17.75,9 Z" id="Path" fill="#472B29"/>
                    <rect id="Rectangle" fill="#472B29" transform="translate(6.001000, 5.001000) rotate(-32.479102) translate(-6.001000, -5.001000) " x="-0.518097654" y="4.75099626" width="13.0381953" height="1"/>
                    <rect id="Rectangle" fill="#472B29" transform="translate(3.252528, 3.248732) rotate(-57.528808) translate(-3.252528, -3.248732) " x="3.00251674" y="-0.0109172428" width="1" height="6.51929759"/>
                    <path d="M11.5,8.75 C11.454,8.75 11.407,8.737 11.366,8.711 L7.83,6.461 C7.714,6.387 7.679,6.232 7.753,6.116 C7.828,5.999 7.984,5.967 8.098,6.039 L11.634,8.289 C11.75,8.363 11.785,8.518 11.711,8.634 C11.663,8.709 11.583,8.75 11.5,8.75 Z" id="Path" fill="#472B29"/>
                </g>
                <g id="Group" transform="translate(40.000000, 41.000000)">
                    <rect id="Rectangle" fill="#C5DEF0" x="6.375" y="1.375" width="11.25" height="7.25"/>
                    <path d="M17.625,9 L6.375,9 C6.168,9 6,8.832 6,8.625 L6,1.375 C6,1.168 6.168,1 6.375,1 L17.625,1 C17.832,1 18,1.168 18,1.375 L18,8.625 C18,8.832 17.832,9 17.625,9 Z M6.75,8.25 L17.25,8.25 L17.25,1.75 L6.75,1.75 L6.75,8.25 Z" id="Shape" fill="#472B29"/>
                    <rect id="Rectangle" fill="#472B29" transform="translate(12.001100, 5.001200) rotate(-32.479102) translate(-12.001100, -5.001200) " x="5.48200235" y="4.75119626" width="13.0381953" height="1"/>
                    <rect id="Rectangle" fill="#472B29" transform="translate(9.253228, 3.248732) rotate(-57.528808) translate(-9.253228, -3.248732) " x="9.00321674" y="-0.0109172428" width="1" height="6.51929759"/>
                    <path d="M17.5,8.75 C17.454,8.75 17.407,8.737 17.366,8.711 L13.83,6.461 C13.714,6.387 13.679,6.232 13.753,6.116 C13.828,6 13.983,5.966 14.098,6.039 L17.634,8.289 C17.75,8.363 17.785,8.518 17.711,8.634 C17.663,8.709 17.583,8.75 17.5,8.75 Z" id="Path" fill="#472B29"/>
                    <path d="M3.75,1.5 L0.25,1.5 C0.112,1.5 0,1.388 0,1.25 C0,1.112 0.112,1 0.25,1 L3.75,1 C3.888,1 4,1.112 4,1.25 C4,1.388 3.888,1.5 3.75,1.5 Z" id="Path" fill="#472B29"/>
                    <path d="M3.75,4 L0.25,4 C0.112,4 0,3.888 0,3.75 C0,3.612 0.112,3.5 0.25,3.5 L3.75,3.5 C3.888,3.5 4,3.612 4,3.75 C4,3.888 3.888,4 3.75,4 Z" id="Path" fill="#472B29"/>
                    <path d="M3.75,6.5 L0.25,6.5 C0.112,6.5 0,6.388 0,6.25 C0,6.112 0.112,6 0.25,6 L3.75,6 C3.888,6 4,6.112 4,6.25 C4,6.388 3.888,6.5 3.75,6.5 Z" id="Path" fill="#472B29"/>
                    <path d="M3.75,9 L0.25,9 C0.112,9 0,8.888 0,8.75 C0,8.612 0.112,8.5 0.25,8.5 L3.75,8.5 C3.888,8.5 4,8.612 4,8.75 C4,8.888 3.888,9 3.75,9 Z" id="Path" fill="#472B29"/>
                </g>
            </g>
        </g>
    </g>
</svg>
)
export default QuestionIcon