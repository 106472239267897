import React from 'react';

const EmailIcon = (props: any) => (
<svg width="51px" height="48px" viewBox="0 0 51 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="22.-Developer-rebranding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Step-4.5" transform="translate(-614.000000, -219.000000)" fillRule="nonzero">
            <g id="icons8-email_open" transform="translate(614.000000, 219.000000)">
                <polygon id="Path" fill="#000000" opacity="0.3" points="3 18 3 46 49 46 49 18 26 33.2727273"/>
                <polygon id="Path" fill="#172B4D" points="41 25 35.8333333 25 35.8333333 5 15.1666667 5 15.1666667 25 10 25 10 0 41 0"/>
                <path d="M48.45,12.8571429 L38.25,7 L38.25,12.8571429 L45.645,17.1863354 L25.5,29.6645963 L5.355,17.1863354 L12.75,12.8571429 L12.75,7 L2.55,12.8571429 C1.02,13.8757764 0,15.6583851 0,17.4409938 L0,42.9068323 C0,45.7080745 2.295,48 5.1,48 L45.9,48 C48.705,48 51,45.7080745 51,42.9068323 L51,17.4409938 C51,15.6583851 49.98,13.8757764 48.45,12.8571429 Z M5.1,42.9068323 L5.1,23.0434783 L25.5,35.7763975 L45.9,23.0434783 L45.9,42.9068323 L5.1,42.9068323 Z" id="Shape" fill="#172B4D"/>
            </g>
        </g>
    </g>
</svg>
)
export default EmailIcon