import styled from 'styled-components';

import SectionTwoBg from '../icons/homepage_sectiontwo.svg';

export const SectionParent = styled.section`
  padding: 0rem 18rem;
  @media (max-width: 1680px) and (min-width: 1440px) {
    padding: 0rem 14rem;
  }
  @media (max-width: 1440px) and (min-width: 768px) {
    padding: 0rem;
  }
  @media (max-width: 768px) {
    padding-top: 9px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media (max-width: 520px) {
    padding: 1rem;
  }
`;

export const H3 = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  @media (max-width: 1440px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 520px) {
    text-align: center;
    font-size: 14px;
  }
`;
export const H1 = styled.h1`
  font-family: 'BlenderPro', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 28px;
  @media (max-width: 1440px) {
    font-size: 28px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 520px) {
    text-align: center;
    font-size: 20px;
  }
`;
export const Button = styled.button`
  height: 3rem;
  padding: 0rem 2rem;
  border-radius: 2px;
  cursor: pointer;
  margin: 10px;
  overflow: hidden;
  border-width: 0;
  outline: none;
  border-radius: 4px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  color: #fff;
  font-family: 'BlenderPro', sans-serif;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 520px) {
    font-size: 12px;
    padding: 0rem 1rem;
    height: 2.5rem;
  }
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-rendering: geometricprecision;
  letter-spacing: normal;
  transition: background-color 0.3s;
`;

export const BlueButton = styled(Button)`
  background-color: #4c49c9;
  :hover {
    background-color: #07047a;
  }
  :active {
    background-color: #573b77;
  }
`;

export const ThankYouSection = styled.section`
  background-color: #6652b0;
  background-image: url(${SectionTwoBg});
  padding: 15rem 5rem !important;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  background-position-y: bottom;
  display: grid;
  align-content: center;
  justify-items: center;
  z-index: 2;
`;

export const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  /* padding: 50%; */
  z-index: 4;
  position: fixed;
  background-color: white;
  display: grid;
  justify-content: center;
  align-content: center;
  top: 0;
  left: 0;
`;
export const Loaders = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  :after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #4636df;
    border-color: #4636df transparent #4636df transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const BookdemoBreadcrumbs = styled.div`
  font-size: 16px;
  color: white;
  font-family: 'BlenderPro', sans-serif;
  font-weight: 500;
`;

export const Rectangle = styled.div`
  height: 79px;
  background-color: #4636df;
  display: grid;
  align-content: center;
  padding: 0rem 18rem 0rem 18rem;
  @media (max-width: 1440px) {
    padding: 0rem 12rem 0rem 12rem;
  }
  @media (min-width: 70em) and (max-width: 60em) {
    padding: 0rem 18rem 0rem 18rem;
  }
  @media (min-width: 60em) and (max-width: 50em) {
    padding: 0rem 13rem 0rem 13rem;
  }
  @media (min-width: 50em) and (max-width: 60em) {
    padding: 0rem 4rem 0rem 4rem;
  }
  @media (max-width: 768px) {
    padding: 0rem 2rem 0rem 2rem;
    height: 69px;
  }
`;

export const BookDemoPageContainer = styled.div`
  display: grid;
  justify-items: left;
`;

export const PrivacyPolicy = styled.div`
  margin-top: 5rem;
  color: #fff;
  letter-spacing: -0.85px;
  font-size: 57px;
  text-align: center;
  font-family: 'BlenderPro', sans-serif;
  font-weight: 500;
`;

export const PrivacyPolicyContent = styled(SectionParent)`
  margin-bottom: 5rem;
  margin-top: 3rem;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: justify;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #122a38;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    text-align: justify;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
    text-align: justify;
  }
`;

export const MainPage = styled.div`
  padding-top: 90px;
  @media (max-width: 768px) {
    padding-top: 70px;
  }
`;
