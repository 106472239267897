import React from "react";
import styled from "styled-components";
const ParentContainer = styled.div`
  background: white;
  border: solid 1px #e6e8ea;
  border-radius: 5px;
  min-width: 30rem;
  height: auto;
  padding: 30px;
`;
const Parent = styled.div`
margin-top: ${(props : { signupType: string }) => props.signupType === 'NEW' ? '2rem' : '8rem'};
display: grid;
justify-items: center;
`;
const BoxText = styled.div`
  font-size: 18px;
  text-align: center;
  font-family: "IBM Plex Sans", sans-serif;
  padding-top: 2rem;
`;
export default (props: any) => {
  return (
    <Parent signupType={props.signupType}>
      <ParentContainer>
        <BoxText>
          Thank you for filling in the info
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>{props.email}</span>
        </BoxText>
      </ParentContainer>
    </Parent>
  );
};
