import React from "react";
import EmailVerificationClass from "./VerifyEmail";
import EsperSignup from "./EsperSignupComponent";
import EsperLogo from "./assets/icons/Esper";
import styled from "styled-components";
import ThankYouPage from "./ThankYouPage";
import Question from "./Question";
import InvalidKey from "./InvalidKey";
import ApiHost from "./appHost";
import axios from "axios";
const ParentSection = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #fafafa;
  min-height: 100vh;
`;
const LogoContainer = styled.div`
  width: auto;
  height: 3rem;
  display: grid;
  justify-content: center;
  /* margin-left: 5rem; */
`;
const LogoStyle: React.CSSProperties = {
  fill: "#4c49c9",
  width: "15rem"
};

interface IApp {
  formSubmitted: boolean;
  email: string;
  invalidKey: boolean;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  languageSelected: boolean;
  keyValue: string | null;
  signupType: string
}
class App extends React.PureComponent<any, IApp> {
  constructor(props: any) {
    super(props);
    this.state = {
      formSubmitted: false,
      email: "",
      firstName: "",
      lastName: "",
      invalidKey: false,
      isLoading: true,
      languageSelected: false,
      keyValue: "",
      signupType: ""
    };
  }
  componentDidMount() {
    let params = new URL(`${document.location}`).searchParams;
    let key = params.get("key");
    axios({
      url: `${ApiHost.hostUrl}user`,
      params: {
        key
      },
      method: "GET"
    })
      .then(result => {
        this.setState({
          isLoading: false,
          keyValue: key,
          signupType: result.data.signup_type,
          firstName: result.data.first_name,
          lastName: result.data.last_name
        });
      })
      .catch(error => {
        this.setState({
          invalidKey: true,
          isLoading: false
        });
      });
    // TODO show invalid key if key is not present or is of length less than 512
  }

  toggle = (value: boolean) => {
    this.setState({
      formSubmitted: value
    });
  };

  toggleLanguage = (value: boolean) => {
    this.setState({
      languageSelected: value
    });
  };

  handleEmail = (value: string) => {
    this.setState({
      email: value
    });
  };

  render() {
    return (
      <div className="App">
        {this.state.isLoading === false &&
          (this.state.invalidKey === true ? (
            <InvalidKey />
          ) : (
              <ParentSection>
                <LogoContainer>
                  <EsperLogo style={LogoStyle} />
                </LogoContainer>
                {this.state.signupType === "NEW" ? <EmailVerificationClass
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  submit={this.toggle}
                  email={this.handleEmail}
                /> : this.state.signupType === "OLD" && this.state.formSubmitted !== true && <EsperSignup
                  submit={this.toggle}
                  email={this.handleEmail}
                />}
                {(this.state.signupType === "NEW" || (this.state.signupType === 'OLD' && this.state.formSubmitted === true)) &&
                  (this.state.languageSelected === false ? (
                    <Question
                      signupType={this.state.signupType}
                      language={this.toggleLanguage}
                      keyValue={this.state.keyValue}
                    />
                  ) : (
                      <ThankYouPage
                        signupType={this.state.signupType}
                        email={this.state.email} />
                    ))}
              </ParentSection>
            ))}
      </div>
    );
  }
}

export default App;
