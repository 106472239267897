import React from "react";
import EsperLogo from "./assets/icons/Esper";
import styled from "styled-components";
import EmailIcon from "./assets/icons/EmailIcon";

const LogoContainer = styled.div`
  width: auto;
  height: auto;
  display: grid;
  justify-content: center;
`;
const ParentContainer = styled.div`
  background: white;
  max-width: 30rem;
  height: auto;
  padding: 30px;
  border: solid 1px #e6e8ea;
  border-radius: 5px;
`;
const Parent = styled.div`
  padding-top: 5rem;
  padding-bottom: 5rem;    
  padding-left: 1rem;
  padding-right: 1rem;
  display: grid;
  background: #e6e8ea;
  justify-content: center;
  height: 100vh;
  align-content: baseline;
`;
const LogoStyles : React.CSSProperties = {
  fill: "#4c49c9",
  width: "15rem",
};
const LogoStyleEmail : React.CSSProperties = {
    width: "6rem",
    height: "6rem"
  };
  const BoxText = styled.div`
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  text-align: center;
  padding-top: 2rem;
;`
export default (props: any) => {
  return (
    <Parent>
      <LogoContainer>
        <EsperLogo style={LogoStyles} />
      </LogoContainer>
      <ParentContainer>
          <LogoContainer>
              <EmailIcon style={LogoStyleEmail} />
          </LogoContainer>
          <BoxText>
          Whoops you seem to have followed an invalid link. <br />
          </BoxText>
      </ParentContainer>
    </Parent>
  );
};
