import React from "react";
import styled from "styled-components";
import QuestionIcon from "./assets/icons/QuestionIcon";
import { BlueButton } from "./assets/styles/StyledComponents";
import axios from "axios";

const LogoContainer = styled.div`
  width: auto;
  height: auto;
  display: grid;
  justify-content: center;
`;

const Container = styled.div`
  margin-top: ${(props : { signupType: string }) => props.signupType === 'NEW' ? '2rem' : '8rem'};
  display: grid;
  justify-items: center;
`

const ParentContainer = styled.div`
  background: white;
  min-width: 30rem;
  border: solid 1px #e6e8ea;
  border-radius: 5px;
  height: auto;
  padding: 30px;
  margin-left: 1rem;
  margin-right: 1rem;
`;
const StyledInputs = styled("input")`
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    color: black;
    background-image: none;
  }
  box-shadow: ${(props: any) =>
    props.error ? "0 0 0 0.2rem #fd7e7e5e" : "none"};
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  font-size: 12px;
  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
  line-height: 1.43;
  ::placeholder {
    color: rgba(107, 124, 147, 0.6);
    /* font-weight: 600; */
    opacity: 1; /* Firefox */
  }
  /* min-height: 3.5em; */
  padding: 1.07em 0.5em;
  margin-top: 10px;
  height: 38px;
  width: 40%;
  border: 1px rgba(107, 124, 147, 0.43) solid;
  border-color: ${(props: any) =>
    props.error ? "#eb6161" : "rgba(107, 124, 147, 0.43)"};
  padding: 0.475rem 0.75rem;
  border-radius: 2px;
  background-position-y: center;
  background-position-x: 0.5rem;
  background-repeat: no-repeat;
  text-indent: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2rem;
`;
const BlueButtonNew = styled(BlueButton)`
  /* width: 20%; */
  padding: 0rem 1rem;
  height: 30px;
  font-size: 14px;
  /* margin: 0; */
`;
const LogoStyleEmail: React.CSSProperties = {
  width: "6rem",
  height: "6rem"
};
const BoxText = styled.div`
  font-size: 16px;
  text-align: start;
  margin-bottom: 1rem;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  padding-top: 2rem;
`;
const RadioSection = styled.div`
  height: 20px;
  width: 100%;
`;
const RadioSection2 = styled.div`
  height: 20px;
  width: 7.5rem;
`;

const ImportSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 520px) {
  grid-template-columns: auto auto;
  }
`;

interface IQuestion {
  prefferedLanguage: string;
  otherLanguage: string;
}
export default class Question extends React.PureComponent<any, IQuestion> {
  constructor(props: any) {
    super(props);
    console.log(props.keyValue);
    this.state = {
      prefferedLanguage: "",
      otherLanguage: ""
    };
  }

  handleLanguage = (event: any) => {
    if (event.target) {
      this.setState({
        prefferedLanguage: event.target.value
      });
    }
  };
  handleOtherLanguage = (event: any) => {
    if (event.target) {
      this.setState({
        otherLanguage: event.target.value
      });
    }
  };
  handleButtonClick = () => {
    console.log("key", this.props.keyValue);
    axios({
      url: "https://cg289vlvl4.execute-api.ap-south-1.amazonaws.com/Prod/user",
      method: "PATCH",
      data: {
        dev_lang:
          this.state.prefferedLanguage === "Other"
            ? this.state.otherLanguage
            : this.state.prefferedLanguage
      },
      params: {
        user_hash: this.props.keyValue,
        survey: true
      }
    }).then(() => {
      this.props.language(true);
    });
  };
  handleSkip = () => {
    this.props.language(true);
  };
  render() {
    return (<Container signupType={this.props.signupType}>
        <ParentContainer>
          <LogoContainer>
            <QuestionIcon style={LogoStyleEmail} />
          </LogoContainer>
          <BoxText>What is your primary programming language?</BoxText>
          <ImportSection
          >
            <RadioSection>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="Python"
                value="Python"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "Python"}
              />
              Python
            </RadioSection>
            <RadioSection>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="kotlin"
                value="Kotlin"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "Kotlin"}
              />
              Kotlin
            </RadioSection>
            <RadioSection>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="Java"
                value="Java"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "Java"}
              />
              Java
            </RadioSection>
            <RadioSection>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="javascript"
                value="Javascript"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "Javascript"}
              />
              JavaScript
            </RadioSection>
            <RadioSection2>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="C++"
                value="C/C++"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "C/C++"}
              />
              C/C++
            </RadioSection2>
            <RadioSection2>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="other"
                value="Other"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "Other"}
              />
              Other
            </RadioSection2>
            <RadioSection2>
              <input
                type="radio"
                style={{
                  marginRight: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif"
                }}
                name="idontcode"
                value="I dont code"
                onClick={this.handleLanguage}
                checked={this.state.prefferedLanguage === "I dont code"}
              />
              I don't code
            </RadioSection2>
          </ImportSection>
          {this.state.prefferedLanguage === "Other" && (
            <StyledInputs
              className="language"
              placeholder="Enter your preferred language"
              type="text"
              autoComplete="off"
              style={{
                height: "20px",
                marginTop: "2rem"
              }}
              onChange={this.handleOtherLanguage}
            />
          )}
          <ButtonContainer>
            <BlueButtonNew
              onClick={this.handleSkip}
              style={{ background: "white", color: "#42526e" }}
            >
              Skip
            </BlueButtonNew>
            <BlueButtonNew onClick={this.handleButtonClick}>
              Continue
            </BlueButtonNew>
          </ButtonContainer>
        </ParentContainer>
        </Container>
    );
  }
}
