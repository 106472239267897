import React from "react";
import styled from "styled-components";
import EmailIcon from "./assets/icons/EmailIcon";

const LogoContainer = styled.div`
  width: auto;
  height: auto;
  display: grid;
  justify-content: center;
`;
const ParentContainer = styled.div`
  background: white;
  min-width: 30rem;
  height: auto;
  padding: 30px;
  border: solid 1px #e6e8ea;
  border-radius: 5px;
`;

const Container = styled.div`
display: grid;
justify-items: center;
margin-top: 10rem;
`

const LogoStyleEmail : React.CSSProperties = {
    width: "6rem",
    height: "6rem"
  };
  const BoxText = styled.div`
  font-size: 18px;
  font-family: 'IBM Plex Sans', sans-serif;
  text-align: center;
  padding-top: 2rem;
;`

interface IEmailVerificationClass {
  locationIndia: boolean;
  userName: string;
  userNameError: boolean;
  userNameErrorText: string;
  password: string;
  endPoint: string;
  location: string;
  locationError: boolean;
  locationErrorText: string;
  serverError: boolean;
  serverErrorText: string;
  errorMessage: string;
  tenantError: boolean;
  tenantErrorText: string;
  signUpError: boolean;
  // serverLocationArray: string[];
  serverLocation: {
    label: string;
    value: string;
  };
  confirmPassword: string;
  passwordError: boolean;
  passwordLengthError: boolean;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  keyValue: string | null;
  hasError: boolean;
  sending: boolean;
}
export default class EmailVerificationClass extends React.PureComponent<
  any,
  IEmailVerificationClass
> {
  constructor(props: any) {
    super(props);
    this.state = {
      locationIndia: false,
      userName: "",
      userNameError: false,
      userNameErrorText: "Enter a valid username",
      password: "",
      endPoint: "",
      keyValue: "",
      location: "",
      locationError: false,
      locationErrorText: "Enter a valid location",
      serverError: false,
      serverErrorText: "",
      errorMessage: "",
      tenantError: false,
      tenantErrorText: "Enter a valid endpoint",
      signUpError: false,
      // serverLocationArray: [],
      serverLocation: {
        label: "California",
        value: "us-west-1"
      },
      confirmPassword: "",
      passwordError: false,
      passwordLengthError: false,
      isLoading: false,
      firstName: "",
      lastName: "",
      hasError: false,
      sending: false
    };
  }

  componentDidMount() {
    let params = new URL(`${document.location}`).searchParams;
    let key = params.get("key");
    this.setState({
      keyValue: key
    });
  }

  render() {
    return (
      <Container>
    <ParentContainer>
        <LogoContainer>
            <EmailIcon style={LogoStyleEmail} />
        </LogoContainer>
        <BoxText>
        
        <div>Thank you</div>
          Your email address is verified<br />
        </BoxText>
    </ParentContainer>
    </Container>
    );
  }
}
