import React from "react";
import { BlueButton, H1 } from "./assets/styles/StyledComponents";
import styled from "styled-components";
import axios from "axios";
import InfoIcon from "./assets/icons/InfoIcon";
import Spinner from "@shoonya/storybook/build/lib/components/Spinner";
import appHost from "./appHost";

const SignUpContainer = styled.div`
  max-width: 30rem;
  height: auto;
  border: solid 1px #e6e8ea;
  background-color: white;
  border-radius: 5px;
  padding: 30px 15px;
  display: grid;
  justify-self: center;
  border-radius: 8px;
  border: 1px solid #e6e8ea;
`;
const FormParent = styled.div`
  padding: 4rem;
  padding-top: 1.5rem;
  @media (max-width: 520px) {
    padding: 2rem;
  }
`;
const StyledInputs = styled("input")`
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    color: black;
    background-image: none;
  }
  box-shadow: ${(props: any) =>
    props.error ? "0 0 0 0.2rem #fd7e7e5e" : "none"};
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  font-size: 1rem;
  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
  line-height: 1.43;
  ::placeholder {
    color: rgba(107, 124, 147, 0.6);
    /* font-weight: 600; */
    opacity: 1; /* Firefox */
  }
  /* min-height: 3.5em; */
  padding: 1.07em 0.5em;
  margin-top: 10px;
  height: 38px;
  width: 100%;
  border: 1px rgba(107, 124, 147, 0.43) solid;
  border-color: ${(props: any) =>
    props.error ? "#eb6161" : "rgba(107, 124, 147, 0.43)"};
  padding: 0.475rem 0.75rem;
  border-radius: 2px;
  background-position-y: center;
  background-position-x: 0.5rem;
  background-repeat: no-repeat;
  text-indent: 10px;
`;
const FormComponent = styled.div`
  display: grid;
  /* width: 100%; */
  padding-left: 0;
  padding-top: 10rem !important;
  justify-items: center;
  @media (max-width: 1700px) {
    padding: 1rem;
  }
`;
const SignUpHeading = styled(H1)`
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  font-weight: 800;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #172b4d;
  text-align: center;
  margin-bottom: 2rem;
`;
const Placeholder = styled.div`
  margin-bottom: 1rem;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
`;
const BlueButtonNew = styled(BlueButton)`
  width: 82%;
  margin-left: 1.5rem;
  justify-self: center;
  margin: 0;
  margin-bottom: 2rem;
`;
const EndpointText = styled.div`
  color: #717a84;
  font-size: 12px;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  margin-bottom: 1rem;
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;
const HeaderContent = styled.div`
  color: #172b4d;
  font-size: 16px;
  text-align: center;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
`;
// const RadioSection = styled.div`
//   height: 20px;
//   width: 100%;
//   padding: 10px;
//   border: 1px solid #e6e8ea;
//   font-family: 'IBM Plex Sans', sans-serif;
// `;
const AlertSection = styled.div`
  display: flex;
  font-size: 13px;
  color: #172b4d;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
  font-style: italic;
`;
const ErrorSection = styled.div`
  font-size: 12px;
  color: #ff0100;
  margin-top: 10px;
  padding-bottom: 1rem;
  font-family: "'IBM Plex Sans', sans-serif", sans-serif;
`;
const PasswordMatchError = styled.div`
  font-size: 12px;
  color: #ff0100;
  margin-top: 8px;
  margin-bottom: 12px;
  font-family: "Nunito Sans", sans-serif;
`;

interface IEmailVerificationClass {
  userName: string;
  userNameError: boolean;
  userNameErrorText: string;
  password: string;
  endPoint: string;
  serverError: boolean;
  serverErrorText: string;
  errorMessage: string;
  tenantError: boolean;
  tenantErrorText: string;
  signUpError: boolean;
  serverLocation: {
    label: string;
    value: string;
  };
  confirmPassword: string;
  passwordError: boolean;
  passwordLengthError: boolean;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  keyValue: string | null;
  hasError: boolean;
  sending: boolean;
}
export default class EmailVerificationClass extends React.PureComponent<
  any,
  IEmailVerificationClass
> {
  constructor(props: any) {
    super(props);
    this.state = {
      userName: "",
      userNameError: false,
      userNameErrorText: "Enter a valid username",
      password: "",
      endPoint: "",
      keyValue: "",
      serverError: false,
      serverErrorText: "",
      errorMessage: "",
      tenantError: false,
      tenantErrorText: "Enter a valid endpoint",
      signUpError: false,
      serverLocation: {
        label: "California",
        value: "us-west-1"
      },
      confirmPassword: "",
      passwordError: false,
      passwordLengthError: false,
      isLoading: false,
      firstName: "",
      lastName: "",
      hasError: false,
      sending: false
    };
  }

  componentDidMount() {
    let params = new URL(`${document.location}`).searchParams;
    let key = params.get("key");
    axios({
      url: `${appHost.hostUrl}user`,
      params: {
        key
      },
      method: "GET"
    }).then(result => {
      this.setState({
        firstName: result.data.first_name,
        lastName: result.data.last_name
      });
    });
    axios({
      url:
        "https://keplpz6848.execute-api.ap-south-1.amazonaws.com/v0/getregions",
      method: "GET"
    }).then(result => {
      if (result && result.data && result.data.regions[0]) {
        this.setState({
          serverLocation: {
            label: result.data.regions[0].label,
            value: result.data.regions[0].region
          }
          // serverLocationArray: result.data.regions
        });
      } else {
        this.setState({
          serverLocation: {
            label: "California",
            value: "us-west-1"
          }
          // serverLocationArray: result.data.regions
        });
      }
    });
    this.setState({
      keyValue: key
    });
  }

  handleUsername = (event: any) => {
    if (event.target) {
      this.setState(
        {
          userName: event.target.value
        },
        () => {
          const usernameRegex = /^[a-zA-Z0-9_-]{3,15}$/;
          if (
            this.state.userName.length === 0 ||
            !usernameRegex.test(this.state.userName)
          ) {
            this.setState({
              userNameError: true
            });
          } else {
            this.setState({
              userNameError: false
            });
          }
        }
      );
    }
  };

  handleEndpoint = (event: any) => {
    if ((
      event.target &&
      event.target.value &&
      event.target.value.split(".").length <= 3 &&
      (event.target.value.split(".")[1]
        ? "esper.cloud".indexOf(
            event.target.value.split(".")[1] +
              `${
                event.target.value.split(".")[2]
                  ? `.${event.target.value.split(".")[2]}`
                  : ""
              }`
          ) === 0
        : event.target.value.split(".").length < 3)
    ) || !event.target.value) {
      this.setState(
        {
          endPoint: event.target.value
        },
        () => {
          if (this.state.endPoint.length < 3) {
            this.setState({
              tenantError: true
            });
          } else {
            this.setState({
              tenantError: false
            });
          }
        }
      );
    }
  };

  handlePassword = (event: any) => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPassword = (event: any) =>
    this.setState({ confirmPassword: event.target.value }, () => {
      if (
        this.state.password !== this.state.confirmPassword ||
        this.state.password.length === 0 ||
        this.state.confirmPassword.length === 0
      ) {
        this.setState({
          passwordError: true
        });
      } else {
        this.setState({
          passwordError: false
        });
      }
    });

  validateEndPoint = () => {
    const endPointName = `${this.state.endPoint}${`${
      this.state.endPoint.split(".")[0]
    }.esper.cloud`.replace(this.state.endPoint, "")}`.replace('.esper.cloud', '')
    this.setState(
      {
        userNameError: false,
        serverError: false,
        tenantError: false,
        passwordError: false,
        passwordLengthError: false,
        hasError: false,
        endPoint: endPointName,
        isLoading: true
      },
      () => {
        let hasError = false;

        const usernameRegex = /^[a-zA-Z0-9_-]{3,15}$/;
        if (
          this.state.userName.length === 0 ||
          !usernameRegex.test(this.state.userName)
        ) {
          this.setState({
            userNameError: true,
            hasError: true,
            isLoading: false
          });
          hasError = true;
        }
        if (
          this.state.endPoint.length === 0 ||
          this.state.endPoint.split(".")[1]
        ) {
          this.setState({
            isLoading: false,
            tenantError: true,
            hasError: true
          });
          hasError = true;
        }
        if (
          this.state.password !== this.state.confirmPassword ||
          this.state.password.length === 0 ||
          this.state.confirmPassword.length === 0
        ) {
          this.setState({
            isLoading: false,
            passwordError: true,
            hasError: true
          });
          hasError = true;
        }
        if (!hasError) {
          axios({
            url: "https://nt8o2may4j.execute-api.ap-south-1.amazonaws.com/v0/",
            method: "GET",
            params: {
              tenant: this.state.endPoint
            }
          })
            .then(response => {
              this.setState({ tenantError: false }, () => {
                this.handleSignup();
              });
            })
            .catch(error => {
              this.setState({
                tenantError: true,
                tenantErrorText: error.response.data,
                isLoading: false
              });
            });
        }
      }
    );
  };

  handleSignup = () => {
    axios({
      url: `${appHost.hostUrl}user`,
      method: "PATCH",
      params: {
        user_hash: this.state.keyValue
      },
      data: {
        user_name: this.state.userName,
        endpoint: this.state.endPoint,
        password: this.state.password,
        server_location: this.state.serverLocation.value
      }
    })
      .then(response => {
        this.props.email(response.data.data.email);
        this.setState({ sending: false, isLoading: false });
        this.props.submit(true);
      })
      .catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.length !== 0
        ) {
          error.response.data.forEach((item: any) => {
            if (item.code === 3001) {
              this.setState({
                userNameError: true,
                userNameErrorText: item.message,
                isLoading: false
              });
            } else if (item.code === 3003) {
              this.setState({
                tenantError: true,
                tenantErrorText: item.message,
                isLoading: false
              });
            } else if (item.code === 3005) {
              this.setState({
                serverError: true,
                serverErrorText: item.message,
                isLoading: false
              });
            } else if (item.code === 3007) {
              this.setState({
                passwordLengthError: true,
                isLoading: false
              });
            } else {
              this.setState({
                errorMessage: item.message,
                signUpError: true,
                isLoading: false
              });
            }
          });
        }
      });
  };

  render() {
    return (
      <FormComponent>
        <SignUpContainer>
          <SignUpHeading>Provision Account</SignUpHeading>
          <HeaderContent>
            Welcome{" "}
            <span style={{ fontWeight: "bold" }}>
              {this.state.firstName} {this.state.lastName}
            </span>
          </HeaderContent>
          <FormParent>
            <Placeholder>
              <label
                style={{
                  paddingBottom: "6px",
                  color: "#172b4d",
                  fontFamily: "'IBM Plex Sans', sans-serif",
                  fontSize: "14px"
                }}
              >
                User Name <span style={{ color: "red" }}>*</span>
              </label>
              <StyledInputs
                className="username"
                autoComplete="off"
                type="text"
                maxLength={50}
                placeholder={"Enter a unique username"}
                error={this.state.userNameError}
                style={{
                  height: "35px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginTop: "6px"
                }}
                onChange={this.handleUsername}
              />
            </Placeholder>
            {this.state.userNameError === true && (
              <PasswordMatchError>
                * {this.state.userNameErrorText}
              </PasswordMatchError>
            )}
            <Placeholder>
              <label
                style={{
                  paddingBottom: "6px",
                  color: "#172b4d",
                  fontFamily: "'IBM Plex Sans', sans-serif",
                  fontSize: "14px"
                }}
              >
                Password <span style={{ color: "red" }}>*</span>
              </label>
              <StyledInputs
                value={this.state.password}
                error={
                  this.state.passwordError || this.state.passwordLengthError
                }
                maxLength={150}
                aria-label="Password"
                onChange={this.handlePassword}
                type="password"
                placeholder={"Give a strong password"}
                name="password"
              />
            </Placeholder>
            <Placeholder>
              <label
                style={{
                  paddingBottom: "6px",
                  color: "#172b4d",
                  fontFamily: "'IBM Plex Sans', sans-serif",
                  fontSize: "14px"
                }}
              >
                Confirm Password <span style={{ color: "red" }}>*</span>
              </label>
              <StyledInputs
                value={this.state.confirmPassword}
                error={
                  this.state.passwordError || this.state.passwordLengthError
                }
                maxLength={150}
                aria-label="Confirm-Password"
                onChange={this.handleConfirmPassword}
                type="password"
                placeholder={" Confirm Password"}
                name="password"
              />
            </Placeholder>
            {this.state.passwordError === true &&
              this.state.passwordLengthError === false && (
                <PasswordMatchError>
                  * Password does not match
                </PasswordMatchError>
              )}
            {this.state.passwordLengthError === true &&
              this.state.passwordError === false && (
                <PasswordMatchError>
                  * Password should be more than 3 characters
                </PasswordMatchError>
              )}
            {this.state.serverError === true && (
              <PasswordMatchError>
                * {this.state.serverErrorText}
              </PasswordMatchError>
            )}
            <Placeholder style={{ marginTop: "1rem" }}>
              <label
                style={{
                  paddingBottom: "6px",
                  fontFamily: "'IBM Plex Sans', sans-serif",
                  fontSize: "14px"
                }}
              >
                Name of your endpoint<span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ position: "relative" }}>
                <StyledInputs
                  className="endpoint"
                  placeholder="E.g. foobar.esper.cloud"
                  type="text"
                  value={this.state.endPoint}
                  error={this.state.tenantError}
                  maxLength={50}
                  autoComplete="off"
                  style={{
                    height: "35px",
                    marginTop: "6px",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                  }}
                  onChange={this.handleEndpoint}
                />
                {this.state.endPoint && (
                  <div
                    style={{
                      position: "absolute",
                      top: "1.4rem",
                      left: "1.3rem",
                      color: "#00000040"
                    }}
                  >{`${this.state.endPoint}${`${
                    this.state.endPoint.split(".")[0]
                  }.esper.cloud`.replace(this.state.endPoint, "")}`}</div>
                )}
              </div>
            </Placeholder>
            {this.state.tenantError === true && (
              <PasswordMatchError>
                * {this.state.tenantErrorText}
              </PasswordMatchError>
            )}
            <EndpointText>
              {this.state.endPoint !== ""
                ? `Your login URL will be https://${this.state.endPoint}${`${
                    this.state.endPoint.split(".")[0]
                  }.esper.cloud`.replace(this.state.endPoint, "")}`
                : ""}
            </EndpointText>
            <AlertSection>
              <InfoIcon style={{ paddingLeft: "12px" }} />
              <div style={{ paddingTop: "2px", paddingLeft: "1em" }}>
                Your endpoint name cannot be changed once set
              </div>
            </AlertSection>
            {this.state.signUpError === true && (
              <ErrorSection>* {this.state.errorMessage}</ErrorSection>
            )}
          </FormParent>
          <div style={{ marginLeft: "1.5rem", display: "grid" }}>
            <BlueButtonNew onClick={this.validateEndPoint}>
              {this.state.isLoading === true ? (
                <Spinner style={{ height: "50", stroke: "#dfe3e6" }} />
              ) : (
                "PROVISION ACCOUNT"
              )}
            </BlueButtonNew>
          </div>
          <BottomSection>
            <div style={{ color: "#0052cc", marginRight: "2rem" }}>
              <a
                href="https://esper.io/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#0052cc" }}
              >
                Privacy Policy
              </a>
            </div>
            <div style={{ color: "#0052cc" }}>
              <a
                href="https://esper.io/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#0052cc" }}
              >
                Terms of Service
              </a>
            </div>
          </BottomSection>
        </SignUpContainer>
      </FormComponent>
    );
  }
}
