import React from 'react';

const EsperLogo = (props: any) => ( 
<svg className={props.className} style={props.style} width={props.width ? props.width : '12rem'} height={props.height ? props.height : '12rem'} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 325 123" xmlSpace="preserve">
{/* <style type="text/css">
	.esper-logo-blue
	{
		fill='#6E52B6'
		}
</style> */}
<g>
	<g>
		<path className="esper-logo-blue" d="M164.25,57.26c0,0.54-0.07,1.09-0.07,1.63h-30.8c0.82,7.68,6.66,12.71,14.41,12.71
			c5.58,0,9.79-2.58,11.76-6.19l2.99,1.77c-2.79,4.62-8.09,7.68-14.82,7.68c-10.47,0-17.88-7.48-17.88-17.75
			c0-9.99,7.21-17.75,17.61-17.75C157.79,39.38,164.25,47.95,164.25,57.26z M133.38,55.56h27.33c-0.75-8.3-6.6-12.92-13.26-12.92
			C139.7,42.64,134.06,48.01,133.38,55.56z"/>
		<path className="esper-logo-blue" d="M196.07,65.22c0,5.58-4.9,9.66-12.17,9.66c-6.8,0-11.29-3.2-12.92-7.55l2.92-1.7c1.22,3.67,4.9,5.98,10,5.98
			c4.76,0,8.77-2.11,8.77-6.39c0-9.25-20.6-3.94-20.6-16.18c0-5.37,4.62-9.66,11.56-9.66c5.51,0,9.72,2.65,11.56,6.8l-2.86,1.63
			c-1.43-3.54-4.96-5.17-8.7-5.17c-4.42,0-8.16,2.31-8.16,6.39C175.47,58.14,196.07,52.77,196.07,65.22z"/>
		<path className="esper-logo-blue" d="M242.24,57.12c0,10.06-7.89,17.75-17.61,17.75c-6.6,0-11.83-3.4-14.62-8.64v21.49h-3.4v-47.6h3.4v7.89
			c2.79-5.24,8.02-8.64,14.62-8.64C234.36,39.38,242.24,47.06,242.24,57.12z M238.85,57.12c0-8.09-6.32-14.48-14.41-14.48
			s-14.41,6.39-14.41,14.48c0,8.09,6.32,14.48,14.41,14.48S238.85,65.22,238.85,57.12z"/>
		<path className="esper-logo-blue" d="M284.88,57.26c0,0.54-0.07,1.09-0.07,1.63h-30.8c0.82,7.68,6.66,12.71,14.41,12.71
			c5.58,0,9.79-2.58,11.76-6.19l2.99,1.77c-2.79,4.62-8.09,7.68-14.82,7.68c-10.47,0-17.88-7.48-17.88-17.75
			c0-9.99,7.21-17.75,17.61-17.75C278.42,39.38,284.88,47.95,284.88,57.26z M254.01,55.56h27.33c-0.75-8.3-6.6-12.92-13.26-12.92
			C260.34,42.64,254.69,48.01,254.01,55.56z"/>
		<path className="esper-logo-blue" d="M309.91,39.65v3.26c-5.85,0-11.35,3.6-11.35,12.31v18.9h-3.4v-34h3.4v6.66
			C300.86,41.42,305.28,39.65,309.91,39.65z"/>
	</g>
	<g>
		<path className="esper-logo-blue" d="M39.54,38.79c-0.02-0.01-0.05-0.03-0.07-0.04l-0.01-0.01c0,0,0,0,0,0l-0.01,0c0,0,0,0,0,0
			c-0.08-0.04-0.16-0.08-0.24-0.1c-0.01,0-0.01-0.01-0.02-0.01c0,0,0,0,0,0c0,0-0.01,0-0.01,0C39.08,38.61,39,38.6,38.9,38.6h0
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.01,0-0.01,0-0.02,0c-0.08,0-0.16,0.01-0.23,0.03c-0.01,0-0.02,0-0.03,0c0,0,0,0,0,0
			c-0.02,0.01-0.04,0.02-0.06,0.02c-0.05,0.02-0.1,0.04-0.15,0.06c-0.02,0.01-0.04,0.01-0.06,0.02l-0.01,0c0,0,0,0,0,0l-18.5,10.44
			v-16.9c0-1.01,0.27-2.01,0.79-2.88c0.51-0.87,1.25-1.59,2.14-2.09l16.14-9.11l38.27,21.6v-2.55L41.16,16.92L56.2,8.43
			c1.75-0.99,4-0.99,5.75,0l29.2,16.48l2.14-1.34L63.07,6.51c-2.43-1.37-5.55-1.37-7.98,0l-16.75,9.45l-16.7,9.42
			c-1.23,0.69-2.25,1.7-2.96,2.9c-0.72,1.21-1.09,2.6-1.09,4V51.1v1.89l21.23-11.97l1.2,0.67v-0.08l37.15,20.97v-2.55L39.54,38.79z"
			/>
	</g>
	<g>
		<path className="esper-logo-blue" d="M78.7,63.45L78.7,63.45L78.7,63.45c0.07,0.04,0.13,0.06,0.19,0.08c0.03,0.01,0.06,0.03,0.09,0.04
			c0.19,0.05,0.39,0.05,0.58,0c0.03-0.01,0.06-0.02,0.09-0.04c0.03-0.01,0.05-0.02,0.08-0.03l20.86-11.78V51.1V32.29
			c0-1.4-0.38-2.79-1.09-4c0,0,0,0,0,0c-0.71-1.21-1.73-2.21-2.96-2.9l-3.22-1.82l-2.14,1.34l4.25,2.4
			c0.55,0.31,1.05,0.71,1.47,1.18l-17.61,9.94l-2.1-1.19v2.55l0.98,0.55v20.23l-0.98-0.55v2.55L78.7,63.45L78.7,63.45z M80.39,40.35
			l16.94-9.56l0.68-0.38c0.21,0.6,0.32,1.24,0.32,1.88v18.17l-9.11,5.14l-8.83,4.98V40.35z"/>
	</g>
	<g>
		<path className="esper-logo-blue" d="M79.32,61.18l-1.2-0.67v0.08L39.43,38.75v0h0l-0.01,0c-0.06-0.03-0.12-0.05-0.18-0.07
			c-0.03-0.01-0.06-0.03-0.09-0.04c-0.19-0.05-0.39-0.05-0.58,0c-0.03,0.01-0.06,0.02-0.09,0.04c-0.03,0.01-0.05,0.02-0.08,0.03
			L17.56,50.48v0.62v18.81c0,1.4,0.38,2.79,1.09,4c0,0,0,0,0,0c0.71,1.21,1.73,2.21,2.96,2.9l33.45,18.88
			c2.43,1.37,5.55,1.37,7.98,0l16.75-9.45l16.7-9.42c1.23-0.69,2.25-1.7,2.96-2.9c0.72-1.21,1.09-2.6,1.09-4V51.1v-1.89L79.32,61.18
			z M20.13,71.79c-0.21-0.6-0.32-1.24-0.32-1.88V51.74l9.11-5.14l8.83-4.98v20.23L20.8,71.41L20.13,71.79z M61.93,93.76
			c-1.75,0.99-4,0.99-5.75,0L22.73,74.89c-0.55-0.31-1.05-0.71-1.47-1.18l17.61-9.94l38.11,21.51L61.93,93.76z M98.3,69.91
			c0,1.01-0.27,2.01-0.79,2.88c-0.51,0.87-1.25,1.59-2.14,2.09L79.24,84L39.99,61.84V41.61L78.6,63.4c0.02,0.01,0.05,0.03,0.07,0.04
			l0.01,0.01c0,0,0,0,0,0l0.01,0c0,0,0,0,0,0c0.08,0.04,0.16,0.08,0.24,0.1c0.01,0,0.01,0.01,0.02,0.01c0,0,0,0,0,0
			c0,0,0.01,0,0.01,0c0.09,0.02,0.17,0.03,0.27,0.03h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.01,0,0.01,0,0.02,0
			c0.08,0,0.16-0.01,0.23-0.03c0.01,0,0.02,0,0.03,0c0,0,0,0,0,0c0.02-0.01,0.04-0.02,0.06-0.02c0.05-0.02,0.1-0.04,0.15-0.06
			c0.02-0.01,0.04-0.01,0.06-0.02l0.01,0c0,0,0,0,0,0l18.5-10.44V69.91z"/>
	</g>
</g>
</svg>

)
export default EsperLogo
