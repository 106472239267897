import * as React from 'react'

const InfoIcon = (props: any) => (
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M3,12 C3,7.029 7.029,3 12,3 C16.971,3 21,7.029 21,12 C21,16.971 16.971,21 12,21 C7.029,21 3,16.971 3,12 Z M11,11.0029293 L11,15.9970707 C11,16.5621186 11.4477153,17 12,17 C12.5561352,17 13,16.5509732 13,15.9970707 L13,11.0029293 C13,10.4378814 12.5522847,10 12,10 C11.4438648,10 11,10.4490268 11,11.0029293 Z M12,9 C12.5522847,9 13,8.55228475 13,8 C13,7.44771525 12.5522847,7 12,7 C11.4477153,7 11,7.44771525 11,8 C11,8.55228475 11.4477153,9 12,9 Z" id="path-1"/>
    </defs>
    <g id="22.-Developer-rebranding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Step-4" transform="translate(-496.000000, -712.000000)">
            <g id="standard/status/info-filled" transform="translate(493.000000, 709.000000)">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use id="Combined-Shape" fill="#42526E" fillRule="nonzero" xlinkHref="#path-1"/>
                <g id="Neutral-/-N500" mask="url(#mask-2)" fill="#42526E" fillRule="evenodd">
                    <polygon id="N500" points="0 24 24 24 24 0 0 0"/>
                </g>
            </g>
        </g>
    </g>
</svg>)

export default InfoIcon